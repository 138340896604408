import React from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import MeetingTime from '../components/meeting_blurb';
import Scholarship from '../components/scholarship';
import EconomicGrant from '../components/economic-grant';

import '../css/Home.css';

export default function Home() {
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
    return (
        <div className='flexbox-container'>
            <Card className='maxitem' style={{ margin: '15px' }} raised>
                <CardContent style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    textAlign: 'center'
                }}>
                  Congrats to our Community Service Scholarship Recipient for 2024!!
                  <div className="scholarship">Morgan Loup</div>
                  <span>
                  We had eight highly qualified scholarship applicants and only one open spot.<br></br>
                  The grand winner is Morgan Loup
                  </span>
                </CardContent>
            </Card>
            <Card className='maxitem'>
                <AutoPlaySwipeableViews enableMouseEvents interval={10000} springConfig={{ duration: '0.5s', easeFunction: '...', delay: '0s' }}>
                    <CardMedia style={{ height: '500px' }} image={require('../images/rwb-1.jpg')} />
                    <CardMedia style={{ height: '500px' }} image={require('../images/rwb-2.jpg')} />
                    <CardMedia style={{ height: '500px' }} image={require('../images/skyline3-bg.jpg')} />
                </AutoPlaySwipeableViews>
            </Card>
            <Paper className='maxitem'>
                <div className='flexbox-container'>
                    <Card className='maxitem'>
                        <CardContent className='home-info'>
                            <h1 >Welcome to Westbank Rotary</h1>
                            <h2>Service Above Self</h2>
                        </CardContent>
                    </Card>
                    {/* <Card className='xsmallitem' raised>
                        <CardContent className=''>
                            <h1>Meetings:</h1>
                            <body2>Club meetings of our 60 members are <br />Tuesdays at 12:15<br />at Timberlane Country Club in Gretna, LA</body2>
                            <h1>General Information:</h1>
                            <body2>Interested in getting involved in the Rotary Club of the Westbank?</body2>
                            <h1>Mailing Address:</h1>
                            <body2>P.O. Box 3407 Gretna, LA 70054</body2>
                        </CardContent>
                    </Card> */}
                    <Card className='xsmallitem' raised>
                        <CardContent className=''>
                            <MeetingTime />
                            <EconomicGrant />
                            <Scholarship />
                        </CardContent>
                    </Card>
                    <Card className='xsmallitem' style={{ textAlign: 'center' }} raised>
                        <iframe title='facebook_feed' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FRotaryCluboftheWestBank%2F&tabs=timeline&width=340&height=550&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="340" height="550" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder={0} allow="encrypted-media"></iframe>
                        {/* <div class="fb-page" data-href="https://www.facebook.com/RotaryCluboftheWestBank/" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/RotaryCluboftheWestBank/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/RotaryCluboftheWestBank/">Rotary Club of the West Bank</a></blockquote></div> */}
                    </Card>
                    <Card className='smallitem' raised>
                        <iframe title='google_map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d864.388289793026!2d-90.05125761764087!3d29.934763775871225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8620a65bf27c0cc3%3A0xddeb813c91e80f60!2s475%20Franklin%20St%2C%20Gretna%2C%20LA%2070053!5e0!3m2!1sen!2sus!4v1664335856454!5m2!1sen!2sus" width="100%" height="600" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Card>
                </div>
            </Paper>

        </div>
    );
};